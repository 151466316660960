import React from 'react';
import './App.css';
import {
  Routes,
  Route,
  BrowserRouter,
} from 'react-router-dom';

import LeadForm from './pages/LeadForm';
import AccessionForm from './pages/AccessionForm';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/lead/:partner" element={<LeadForm/>} />
        <Route path="/lead" element={<LeadForm/>} />
        <Route path="/accession/:partner" element={<AccessionForm/>} />
        <Route path="/accession" element={<AccessionForm/>} />
        <Route path="/" element={<LeadForm/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;